// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 1,
		title: 'Fragility',
		category: 'Issue Tracking System',
		img: require('@/assets/images/fragilitydemo.png'),
		page: 'projects/fragility'
	},
	{
		id: 2,
		title: 'Tangle',
		category: 'Substitution Cipher Game',
		img: require('@/assets/images/tangledemo.png'),
		page: 'projects/tangle'
	},
	{
		id: 3,
		title: 'EggFlip',
		category: 'Breakfast Recipe App',
		img: require('@/assets/images/eggflip.png'),
		page: 'projects/eggflip'
	},
	{
		id: 4,
		title: 'Flossify',
		category: 'Dental Application',
		img: require('@/assets/images/flossify.png'),
		page: 'projects/flossify'
	},
	{
		id: 5,
		title: 'Amora',
		category: 'Mobile Dating App',
		img: require('@/assets/images/amora.png'),
		page: 'projects/amora'
	},
];

export default projects;
