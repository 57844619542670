<template>
	<div class="container mx-auto">
		<!-- Banner -->
		<AppBanner class="mb-5 sm:mb-8" />

		<!-- Projects -->
		<ProjectsGrid ref="projects" />

		<!-- Load more projects button -->
		<div class="mt-10 sm:mt-20 flex justify-center">
			<router-link
				to="/projects"
				class="font-general-medium flex items-center border px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-github-green-500 hover:bg-github-green-600 focus:ring-white focus:ring-github-green-900 text-white text-lg sm:text-xl duration-300"
				aria-label="More Projects"
			>
				<Button title="More Projects" />
			</router-link>
		</div>
	</div>
</template>

<script>
import AppBanner from '@/components/shared/AppBanner';
import ProjectsGrid from '../components/projects/ProjectsGrid.vue';
import Button from '../components/reusable/Button.vue';

export default {
	name: 'Home',
	components: {
		AppBanner,
		ProjectsGrid,
		Button,
	},
	methods: {
		scrollMeTo() {
			document.getElementById('projects-home').scrollIntoView({ behavior: "smooth" }); 
		}
	},
	mounted() {}
};
</script>

<style scoped></style>
