export const socialLinks = [
  {
    id: 1,
    name: "Website",
    icon: "globe",
    url: "#",
  },
  {
    id: 2,
    name: "GitHub",
    icon: "github",
    url: "https://github.com/tonydube",
  },
  {
    id: 3,
    name: "LinkedIn",
    icon: "linkedin",
    url: "https://www.linkedin.com/in/ajdube",
  },
  {
    id: 4,
    name: "Dribbble",
    icon: "dribbble",
    url: "https://dribbble.com/ajdube",
  },
];
