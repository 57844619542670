<script>
import feather from "feather-icons";

export default {
  name: "Home",
  data: () => {
    return {
      theme: "",
    };
  },
  created() {
    this.theme = localStorage.getItem("theme") || "light";
  },
  mounted() {
    feather.replace();
    this.theme = localStorage.getItem("theme") || "light";
  },
  updated() {
    feather.replace();
  },
  methods: {
    scrollMeTo(ref) {
      console.log(this.$parent)
      this.$parent.scrollMeTo(ref);
    }
  },
};
</script>

<template>
  <section
    class="flex flex-col sm:justify-between items-center sm:flex-row mt-12 sm:mt-10"
  >
    <!-- Banner left contents -->
    <div class="w-full md:w-1/3 text-left">
      <h3
        class="mt-2 text-lg sm:text-xl xl:text-2xl text-center sm:text-left leading-none font-thin dark:text-white mb-7"
      >
        Looking for a dev?
      </h3>
      <h1
        class="font-general-semibold text-5xl md:text-5xl xl:text-6xl text-center sm:text-left text-black dark:text-white"
      >
        I will make users <span class="text-github-green-500">love</span> your
        product.
      </h1>
      <div class="flex justify-center sm:block">
        <a @click="scrollMeTo('projects')"
          class="flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg border py-2.5 sm:py-3 shadow-lg rounded-lg bg-github-green-500 focus:ring-1 focus:ring-github-green-900 hover:bg-github-green-600 text-white duration-500"
        >
          <i
            data-feather="arrow-down"
            class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"
          ></i>
          <span class="text-sm sm:text-lg font-general-medium duration-100 cursor-pointer"
            >View My Work</span
          ></a
        >
      </div>
    </div>

    <!-- Banner right illustration -->
    <div class="w-full md:w-2/3 text-right float-right">
      <img
        v-if="theme === 'light'"
        src="@/assets/images/developer-activity.svg"
        alt="Developer"
      />
      <img v-else src="@/assets/images/developer-activity-dark.svg" alt="Developer" />
    </div>
  </section>
</template>

<style>
</style>
