<script>
export default {
  data: () => {
    return {
      copyrightDate: new Date().getFullYear(),
      projectName: "ajdube.com",
    };
  },
};
</script>

<template>
  <div class="flex justify-center items-center text-center">
    <div
      class="font-general-regular text-lg text-ternary-dark dark:text-ternary-light"
    >
      &copy; {{ copyrightDate }}
      <a
        href="#"
        class="font-general-medium hover:underline hover:text-github-green-600 dark:hover:text-github-green-300 duration-500"
      >
        {{ projectName }}
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
