<script>
export default {
	props: {
		label: {
			type: String,
			default: '',
		},
	},
};
</script>

<template>
	<div>
		<label
			class="block text-lg text-primary-dark dark:text-primary-light mb-2"
			>{{ label }}</label
		>
		<textarea
			class="focus:ring-black focus:border-0 dark:focus:ring-white dark:focus:border-0 w-full px-5 py-2 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md text-md"
			:placeholder="label"
			cols="14"
			rows="6"
		></textarea>
	</div>
</template>

<style lang="scss" scoped></style>
